import * as React from "react"
import Layout from "../../components/layout"
import AniLink from "gatsby-plugin-transition-link/AniLink";
import Helmet from 'react-helmet'


const langley = () => {
  return (
  <>
<Helmet
    bodyAttributes={{
        class: 'single-page whitemain'
    }}
/>
  <Layout pageTitle="qvm">
    <section id="main">
      <div id="worktitle">
        <span>
          <h1>Langley Group</h1>
          <span className="highlight"></span>
        </span>
        <span>Learning Management System</span>
      </div>
    </section>

    <section id="work">
      <div className="work">
          <div className="picture workpicture">
		  	<img src={'/img/works/langley_feature.jpg'} alt="Langley Group" />

          </div>

        <div className="seeWork">
          <div className="seeWork__content">
            <div className="icons">
            <img className="picture" src={'/img/single/wordpress-icon.png'} width="139" height="87" alt="Wordpress" />
              <img className="picture" src={'/img/single/woocommerce-icon.png'} width="247" height="50" alt="Woocommerce" />
            </div>
            <a className="button visitwebsite" href="https://langleygroup.com.au/" target="_blank" rel="noreferrer"><span className="seemore"><i>&#xe801;</i></span>Visit Website</a> 

          </div>

        </div>

      </div>
    </section>

    <section id="single-content">
      <div className="single-content">
        <p>At Langley Group we work with organisations to help them develop and make the most of their employees through learning and development.</p>
        <p>In collaboration with CS Creative's design vision, we developed an online learning platform, e-commerce site, and blog using the WooCommerce platform. The project features optimised responsiveness for desktop, tablet, and smartphone users. Additionally, we developed sites for Langley Group Institute, Sue Langley, Work on Wellbeing, Positive HR Toolkits, and LG Foundations. We also manage dedicated private servers to ensure optimal performance and security.</p>
      </div>

      <div className="project-image">
        <div className="left-col">
          <img className="picture" src={'/img/single/langley-desktop.jpg'} alt="Desktop View" />
        </div>
        <div className="right-col">
          <img className="picture" src={'/img/single/langley-mobile.jpg'} alt="Mobile View" />
      </div>
      </div>

    </section>


    <section id="post-pagination">
      <div className="post-pagination">
        <div className="previous">
          <AniLink cover to="/work/word-of-mouth-technology" bg="#00ccd4" direction="right" duration={0.76} className="button project prev">Prev Work</AniLink>
          <p className="project-name">WOM</p>
        </div>
        <div className="next">
          <AniLink cover to="/work/braille-sign-supplies" bg="#00ccd4" direction="right" duration={0.76} className="button project next">Next Work</AniLink>
          <p className="project-name">Braille Sign Supplies</p>
        </div>
      </div>
    </section>
</Layout>

  </>
  )
}

export default langley


export const Head = () => (
  <>
    <title>Langley Group - Tobstar</title>
<meta name="description" content="At Langley Group we work with organisations to help them develop and make the most of their employees through learning and development." />
  </>
)